import React from 'react'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createUseStyles } from 'react-jss'

const DefaultMapMarker = props => {
  const classes = useStyles()
  return (
    <div className={classes.icon}>
      <FontAwesomeIcon icon={faMapMarkerAlt} />
    </div>
  )
}

const useStyles = createUseStyles({
  icon: {
    '& svg': {
      height: '2.2em',
      width: '3.2em !important',
      color: '#f47432',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
})
export default DefaultMapMarker


import * as React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import GoogleMap from '../components/molecules/GoogleMap'
import DefaultMapMarker from '../components/atoms/DefaultMapMarker'

const AreaSubPage = (props) => {
  const { translations, region, path } = props
  const geoCodes = {
    zator: {
      mapCenter: { lat: 49.920009, lng: 19.585285, },
      marker: { lat: 49.9993812, lng: 19.4086305, },
    },
    wadowice: {
      mapCenter: { lat: 49.920009, lng: 19.685285, },
      marker: { lat: 49.8841055, lng: 19.4777605, },
    },
    krakow: {
      mapCenter: { lat: 49.970009, lng: 19.785285, },
      marker: { lat: 50.0618971, lng: 19.9345672, },
    },
    bochnia: {
      mapCenter: { lat: 49.970009, lng: 20.185285, },
      marker: { lat: 49.9690762, lng: 20.4277796, },
    },
    wieliczka: {
      mapCenter: { lat: 49.970009, lng: 20.185285, },
      marker: { lat: 49.9823327, lng: 20.0579787, },
    },
    oswiecim: {
      mapCenter: { lat: 49.970009, lng: 19.685285, },
      marker: { lat: 50.0314994, lng: 19.1860073, },
    },
  }
  const classes = useStyles()
  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_desctiption}/>
      <div className={classes.pageConainer}>
        <div className={classes.leftColumn}>
          <div>
            <div className={classes.title}>
              <h2>{translations.section_title}</h2>
            </div>
            <div className={classes.content}>
              <p>
                {translations.section_description}
              </p>
              <p>
                {translations.section_description_2}
              </p>
            </div>
          </div>
          <div className={classes.readMore}>
            <a href={translations.link} rel="noreferrer" target="_blank" >{translations.read_more}</a>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <GoogleMap
            mapCenter={geoCodes[region].mapCenter}
            zoom={9}
            customStyles={{ width: '100%', height: '50vh', marginBottom: '0px', }}
          >
            <DefaultMapMarker
              {...geoCodes[region].marker}
            />
          </GoogleMap>
        </div>
      </div>
  </Layout>
  )
}

const useStyles = createUseStyles({
  pageConainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftColumn: {
    width: '50%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'justify',
  },
  rightColumn: {
    width: '50%',
    height: '100%',
  },
  title: {
    color: '#94d224',
    margin: 'unset',
    width: '100%',
    marginTop: '2em !important',
    position: 'relative',
    '& h2': {
      fontSize: '22px',
      fontWeight: '700',
    },
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: '30px',
      height: '2px',
      backgroundColor: '#94d224',
      top: '40px',
      left: '0px',
    },
  },
  content: {
    marginTop: '2em',
    fontWeight: '400',
    fontSize: '20px',
    '& p': {
      paddingRight: '0.7em',
    },
  },
  readMore: {
    backgroundColor: '#94d224',
    height: '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
      color: 'white',
      fontSize: '20px',
    },
    '&:hover': {
      filter: 'brightness(1.1)',
    },
  },
  '@media (max-width: 960px)': {
    leftColumn: {
      width: '100%',
    },
    rightColumn: {
      marginTop: '2em',
      width: '100%',
    },
  },
})

AreaSubPage.propTypes = {
  translations: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  return {
    translations: state.translations.translations.area_page.area_subpages[ownProps.region],
  }
}

export default connect(mapStateToProps)(AreaSubPage)

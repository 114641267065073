import React from 'react'
import { connect } from 'react-redux'
import { CHANGE_TRANSLATIONS } from '../state/translations'
import { graphql } from 'gatsby'
import AreaSubPage from '../customPages/areaSubPage'
class areaSubPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    const lang = this.props.data.allSitePage.edges[0].node.path.split('/')[1].toUpperCase()
    const language = [ 'DE', 'EN' ].includes(lang) ? lang : 'PL'
    props.changeTranslations(language)
  }

  render () {
    return (
      <>
        <AreaSubPage path={this.props.location.pathname} region={this.props.data.allSitePage.edges[0].node.context} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTranslations: (payload) => dispatch(CHANGE_TRANSLATIONS(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(areaSubPageTemplate)

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: {path: {eq: $path}}) {
      edges {
        node {
          context
          path
        }
      }
    }
  }
`
